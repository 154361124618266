import React, { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import Spinner from '../../shared/Spinner'
import { Status } from '../../utils/constants'
import { useAuth } from '../../contexts/AuthContext'
import './style.css'
import {
    getSubscriptionDetail,
    getSubscriptions,
    linkSubscriptionWithCategory,
    updateDatabaseFromMercadoPago
} from '../../actions/subscriptionsActions'
import ModalShowSubscription from './modalShowSubscription'
import {
    clearSubscriptionState,
    clearUpdateState
} from '../../slices/subscriptionsSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEdit, faLink } from '@fortawesome/free-solid-svg-icons'
import ModalLinkSubscription from './modalLinkSubscription'

const Subscriptions = (): JSX.Element => {
    const {
        updatingDatabaseStatus,
        subscriptionList,
        getSubscriptionsStatus,
        subscriptionInfo,
        getSubscriptionDetailStatus,
        subscription
    } = useAppSelector((state) => state.subscriptions)
    const dispatch = useAppDispatch()
    const loader = useRef(null)
    const auth = useAuth()

    const [showModal, setShowModal] = useState<boolean>(false)
    const [showLinkModal, setShowLinkModal] = useState<boolean>(false)
    const [selectedSubscription, setSelectedSubscription] = useState<string | null>(null)
    const [selectedStatus, setSelectedStatus] = useState<string>('all')
    const [amountFilter, setAmountFilter] = useState<{
        min: number
        max: number
    }>({ min: 0, max: Infinity }) // State for amount filter

    // Fetch initial users on mount
    useEffect(() => {
        dispatch(getSubscriptions(auth.myUser.id))
    }, [dispatch, auth.myUser.country])

    useEffect(() => {
        switch (updatingDatabaseStatus) {
            case Status.Fetch:
                dispatch(getSubscriptions(auth.myUser.id))
                // sleep and then clear the state
                setTimeout(() => {
                    dispatch(clearUpdateState())
                }, 10000)
                break
            case Status.FetchError:
                setTimeout(() => {
                    dispatch(clearUpdateState())
                }, 10000)
                break
            default:
                break
        }
    }, [updatingDatabaseStatus])

    const showDetail = (id: string): void => {
        setShowModal(true)
        dispatch(
            getSubscriptionDetail({
                id,
                userUid: auth.myUser.id
            })
        )
    }

    const handleCloseModal = (): void => {
        setShowModal(false)
        setShowLinkModal(false)
        setSelectedSubscription(null)
        dispatch(clearSubscriptionState())
    }

    const getRowClass = (status: string): string => {
        switch (status.toLowerCase()) {
            case 'authorized':
                return 'row-active'
            case 'paused':
                return 'row-paused'
            case 'cancelled':
                return 'row-canceled'
            case 'pending':
                return 'row-pending'
            default:
                return 'row-default'
        }
    }

    // Reset filters
    const resetFilters = (): void => {
        setSelectedStatus('all')
        setAmountFilter({ min: 0, max: Infinity })
    }

    // Filter subscriptions based on status and amount
    const filteredSubscriptions = subscriptionList?.filter(
        (sub) =>
            (selectedStatus === 'all' ||
                sub.status.toLowerCase() === selectedStatus) &&
            sub.amount >= amountFilter.min &&
            sub.amount <= amountFilter.max
    )

    const updateDatabase = (): void => {
        if (updatingDatabaseStatus === Status.Idle) {
            dispatch(updateDatabaseFromMercadoPago(auth.myUser.id))
        }
    }

    const handleLinkSubscription = (categoryUid: string): void => {
        if (!selectedSubscription) return
        dispatch(linkSubscriptionWithCategory({
            categoryUid,
            subscriptionId: selectedSubscription
        }))
    }

    return (
        <div>
            <div className="page-header d-flex justify-content-between align-items-center">
                <div className="header-title">
                    <h3 className="page-title d-flex align-items-center">
                        <span className="page-title-icon bg-gradient-primary text-white mr-2">
                            <i className="mdi mdi-account"></i>
                        </span>
                        Listado de Suscriptores
                    </h3>
                </div>
                <button className="btn btn-primary" onClick={updateDatabase}>
                    {updatingDatabaseStatus === Status.Fetching &&
                        'Actualizando base de datos...'}
                    {updatingDatabaseStatus === Status.FetchError &&
                        'Error al actualizar base de datos...'}
                    {updatingDatabaseStatus === Status.Idle &&
                        'Actualizar con base de Mercado Pago'}
                    {updatingDatabaseStatus === Status.Fetch &&
                        'Base de datos actualizada'}
                </button>
            </div>
            {/* Subscription Info Card */}
            {subscriptionInfo && (
                <div className="row">
                    <div className="col-12 grid-margin">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Información general</h4>
                                <div className="info-card">
                                    <p>
                                        <strong>Total Bruto:</strong> $
                                        {subscriptionInfo.totalGrossAmount.toFixed(2)}
                                    </p>
                                    <p>
                                        <strong>Total Neto:</strong> $
                                        {subscriptionInfo.totalNetAmount.toFixed(2)}
                                    </p>
                                    <p>
                                        <strong>Cantidad usuarios pausados:</strong>{' '}
                                        {subscriptionInfo.totalPaused}
                                    </p>
                                    <p>
                                        <strong>Cantidad usuarios activos:</strong>{' '}
                                        {subscriptionInfo.totalAuthorized}
                                    </p>
                                    <p>
                                        <strong>Cantidad usuarios cancelados:</strong>{' '}
                                        {subscriptionInfo.totalCanceled}
                                    </p>
                                    <p>
                                        <strong>Cantidad usuarios pendientes:</strong>{' '}
                                        {subscriptionInfo.totalPending}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/* Filter Section */}
            <div className="row mb-3">
                <div className="col-md-3">
                    <label htmlFor="statusFilter">Filtrar por estado:</label>
                    <select
                        id="statusFilter"
                        className="form-control"
                        value={selectedStatus}
                        onChange={(e) => {
                            setSelectedStatus(e.target.value.toLowerCase())
                        }}
                    >
                        <option value="all">Todos</option>
                        <option value="authorized">Activos</option>
                        <option value="paused">Pausados</option>
                        <option value="cancelled">Cancelados</option>
                        <option value="pending">Pendientes</option>
                    </select>
                </div>
                <div className="col-md-3">
                    <label htmlFor="minAmount">Monto mínimo:</label>
                    <input
                        id="minAmount"
                        type="number"
                        className="form-control"
                        value={amountFilter.min}
                        onChange={(e) => {
                            setAmountFilter({ ...amountFilter, min: Number(e.target.value) })
                        }}
                        placeholder="Monto mínimo"
                    />
                </div>
                <div className="col-md-3">
                    <label htmlFor="maxAmount">Monto máximo:</label>
                    <input
                        id="maxAmount"
                        type="number"
                        className="form-control"
                        value={amountFilter.max === Infinity ? '' : amountFilter.max}
                        onChange={(e) => {
                            setAmountFilter({
                                ...amountFilter,
                                max: e.target.value ? Number(e.target.value) : Infinity
                            })
                        }}
                        placeholder="Monto máximo"
                    />
                </div>
                <div className="col-md-3 d-flex align-items-end">
                    <button className="btn btn-secondary w-100" onClick={resetFilters}>
                        Restablecer Filtros
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Suscriptores</h4>
                            <div className="table-responsive user-table">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th> ID </th>
                                            <th> Nombre </th>
                                            <th> Apellidos </th>
                                            <th> Estado </th>
                                            <th> Monto Suscripción </th>
                                            <th> Acción </th> {/* New Column for Actions */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredSubscriptions?.map((value) => (
                                            <tr
                                                className={getRowClass(value.status)}
                                                key={value.id}
                                            >
                                                <td> {value.id} </td>
                                                <td> {value.firstName} </td>
                                                <td> {value.lastName} </td>
                                                <td> {value.statusName} </td>
                                                <td> ${value.amount.toFixed(2)} </td>
                                                <td>
                                                    {/* View Icon */}
                                                    <FontAwesomeIcon
                                                        icon={faEye}
                                                        className="text-info mr-3"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            showDetail(value.id)
                                                        }}
                                                        title="Ver"
                                                    />

                                                    {value.categoryUid ? (
                                                        // Edit Icon
                                                        <FontAwesomeIcon
                                                            icon={faEdit}
                                                            className="text-primary mr-3"
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                setSelectedSubscription(value.id)
                                                                setShowLinkModal(true)
                                                            }}
                                                            title="Editar"
                                                        />
                                                    ) : (
                                                        // Link Icon
                                                        <FontAwesomeIcon
                                                            icon={faLink}
                                                            className="text-secondary"
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                setSelectedSubscription(value.id)
                                                                setShowLinkModal(true)
                                                            }}
                                                            title="Vincular"
                                                        />
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div ref={loader} />
                                {getSubscriptionsStatus === Status.Fetching && <Spinner />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal to display subscription details */}
            <ModalShowSubscription
                onClose={handleCloseModal}
                show={showModal}
                isFetching={getSubscriptionDetailStatus === Status.Fetching}
                value={subscription}
            />
            {/* Modal to link subscription */}
            <ModalLinkSubscription
                onClose={handleCloseModal}
                show={showLinkModal}
                onSave={handleLinkSubscription}
            />
        </div>
    )
}

export default Subscriptions
